// extracted by mini-css-extract-plugin
export var buttonsContainer = "OffersPreview-module--buttonsContainer--v15CO";
export var category = "OffersPreview-module--category--M7ERt";
export var container = "OffersPreview-module--container--5NK4h";
export var cross = "OffersPreview-module--cross--tUVr1";
export var crossHolder = "OffersPreview-module--crossHolder--Jnu-w";
export var disabledButton = "OffersPreview-module--disabledButton--HLWk5";
export var heart = "OffersPreview-module--heart--un07-";
export var heartHolder = "OffersPreview-module--heartHolder--Vbdpw";
export var holder = "OffersPreview-module--holder--DzrGW";
export var hoverHeartClass = "OffersPreview-module--hoverHeartClass--7oN2l";
export var icon = "OffersPreview-module--icon--HKtp+";
export var iconHolder = "OffersPreview-module--iconHolder--Wa2HW";
export var link = "OffersPreview-module--link--G5-PN";
export var mwst = "OffersPreview-module--mwst--BY4P5";
export var name = "OffersPreview-module--name--9cZ-T";
export var price = "OffersPreview-module--price--Tcq3g";
export var refPrice = "OffersPreview-module--refPrice--BKz5p";
export var saleBadge = "OffersPreview-module--saleBadge--kCKF-";
export var showMore = "OffersPreview-module--showMore--k9EQR";