import * as React from "react";
import {Container} from "react-bootstrap";
// @ts-ignore
import * as style from './VendorAGBs.module.scss';

const VendorAGBs = ({vendor}) => {
    return (
        <Container>
            <h2 className={"text-center pt-5"}>AGBs</h2>
            <span dangerouslySetInnerHTML={{__html: vendor.AGB}}>
            </span>
        </Container>
    )
}

export default VendorAGBs;
