import * as React from "react";
import {FC} from "react";
// @ts-ignore
import * as style from './VendorHeader.module.scss';
import {StaticImage} from "gatsby-plugin-image";

const VendorHeader: FC<any> = ({vendor}) => {

    return (
        <div className={style.container}>
            {/*<div className={style.contentHolder}>*/}
            {/*</div>*/}

            {vendor.HeaderImage ?
                <img className={style.backgroundImage} src={vendor.HeaderImageURL}/> :
                <StaticImage src={"../../../images/Headerbild-2x.jpg"} alt={"headerImage"} placeholder={"traceSVG"}
                             className={style.backgroundImage}/>
            }
            {vendor.PreviewImage ?
                <div className={style.content}>
                    <img className={style.vendorHeaderLogo} src={vendor.PreviewImage}/>
                    {/*<h2 className={style.categoryName}>{vendor.CompanyName}</h2>*/}
                </div> : null
            }

        </div>
    )
}

export default VendorHeader;
