import * as React from "react";
import {FC, useEffect, useState} from "react";
import {faEnvelope, faUserAlt, faPhoneAlt} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Container, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './VendorInfo.module.scss';
import OffersPreview from "../OffersPreview/OffersPreview";
import Loading from "../../Loading/Loading";
import {SRLWrapper} from "simple-react-lightbox";
import {StaticImage} from "gatsby-plugin-image";
import {Client, handleApiError} from "../../../services/ApiService";

const VendorInfo: FC<any> = ({vendor, loading}) => {
    const [products, setProducts] = useState([]);


    return (
        <>
            {loading ?
                <Loading type={"grow"} message={"Daten werden geladen"}/> :
                <>
                    <Container>
                        <div className={style.logoHolder + ' row pt-5'}>
                            <Col lg={12}>
                                <h2 className={"text-center order-first"}>
                                    {vendor.CompanyName}
                                </h2>
                            </Col>
                        </div>
                        <div>
                            <span dangerouslySetInnerHTML={{__html: vendor.Description}}
                                  className={style.description}></span>
                            <hr className={'mt-5'}></hr>


                        </div>
                        <div className={style.imageContainer}>
                            <SRLWrapper>
                                <Row>
                                    {vendor.VendorImages &&
                                        <>
                                            {
                                                vendor.VendorImages.map((image, index) => index < 4 && (
                                                        <Col lg={3} md={6} xs={6} key={index}
                                                             className={style.productHolder}>
                                                            <img src={image} className={style.product}/>
                                                        </Col>
                                                    )
                                                )
                                            }
                                        </>
                                    }

                                </Row>
                            </SRLWrapper>
                        </div>
                    </Container>
                    <hr/>
                    <Container>
                        <OffersPreview products={vendor.ActiveProducts}/>
                    </Container>
                </>
            }
        </>

    )
}

export default VendorInfo;
