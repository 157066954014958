import * as React from "react";
import {FC, useEffect, useRef, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
// @ts-ignore
import * as style from './VendorTransparenz.module.scss';
// @ts-ignore
import * as mainStyle from "../../../theme/main.module.scss";
import * as yup from 'yup';
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Client, handleApiError} from "../../../services/ApiService";
import {toast} from "react-toastify";

const mapState = ({member, auth}) => ({
    member,
    auth
})

const connector = connect(mapState, null);

type PropsFromRedux = ConnectedProps<typeof connector>

type VendorTransparenzProps = PropsFromRedux & {
    vendor: any
}

interface FormInputs {
    Description: string,
}

const schema = yup.object().shape({
    Description: yup.string().optional(),
})

const VendorTransparenz: FC<VendorTransparenzProps> = ({vendor, member, auth}) => {
    const editorRef = useRef();
    const [editorLoaded, setEditorLoaded] = useState(false)
    // @ts-ignore
    const {CKEditor, ClassicEditor} = editorRef.current || {}
    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    function onSubmit(data: FormInputs) {
        Client.Auth.authPut(auth.Key, 'Vendor', vendor.ID, {
            Transparency: data.Description
        }).then(() => {
            toast.success('Erfolgreich aktualisiert!');
        }).catch(handleApiError)
    }

    useEffect(() => {
        setDesc(vendor.Transparency);
    }, [vendor])

    useEffect(() => {
        // @ts-ignore
        editorRef.current = {
            // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
            ClassicEditor: require('@ckeditor/ckeditor5-build-classic')
        }
        setEditorLoaded(true)
    }, [])

    const [desc, setDesc] = useState('');
    return (
        <>
            <h2 className={"text-center pt-5"}>Transparenz</h2>
            <div>
                {vendor.Transparency &&
                    <div dangerouslySetInnerHTML={{__html: vendor.Transparency}}>
                    </div>
                }
            </div>
        </>

    )
}

export default connector(VendorTransparenz);
