import * as React from "react";
import {FC, useEffect, useRef, useState} from "react";
import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faTimes} from "@fortawesome/pro-light-svg-icons";
// @ts-ignore
import * as style from './OffersPreview.module.scss';
import {Link} from "gatsby";
import Loading from "../../Loading/Loading";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../../services/ApiService";
import {toast} from "react-toastify";
import {faHeart as hoverHeart} from "@fortawesome/pro-solid-svg-icons";
import {StaticImage} from "gatsby-plugin-image";
import {setBookmarkAmount} from "../../../stores/bookmark/bookmarkSlice";
import {faChevronDoubleLeft, faChevronDoubleRight, faChevronRight, faChevronLeft} from "@fortawesome/pro-light-svg-icons";

const mapState = ({member, auth}) => ({
    member,
    auth
})

const mapDispatch = {
    setBookmarkAmount,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & {
    showAnyway?: boolean
}


const OffersPreview: FC<any & PropsFromRedux> = ({products, auth, member, showAnyway, setBookmarkAmount, loading}) => {
    const maxProductCountToLoadForPaginationOnVendorPageInformation = 42;
    const [productsToShow, setProductsToShow] = useState(maxProductCountToLoadForPaginationOnVendorPageInformation);
    const [moreProducts, setMoreProducts] = useState(false);
    const productRef = useRef(null);
    const [allBookmarks, setAllBookmarks] = useState([]);
    const [page, setPage] = useState(1);
    const [viewProducts, setViewProducts] = useState(0);

    useEffect(() => {
        if (member.MemberID) {
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
            }).catch(handleApiError);
        }
    }, [])

    useEffect(() => {
        if (member.MemberID) {
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
            }).catch(handleApiError);
        }
    }, [member])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [page])

    function addBookmark(id: number, product) {
        Client.Bookmark.add(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt zur Merkliste hinzugefügt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count)

                let basketDataLayer = {
                    'cicData': {
                        'cic_product_title': product.Title,
                        'cic_product_category': product.CategoryTitle,
                        'cic_vendor_title': product.Title,
                        'cic_product_price': product.Price
                    },
                    'event': 'fs.productFavorited'
                };

                //@ts-ignore
                if (window.dataLayer) {

                    let gme_add_to_wishlist = {
                        'event': 'add_to_wishlist',
                        'data': {
                            'currency': 'EUR',
                            'value': product.Price,
                            'items': [{
                                'item_id': product.SKU,
                                'item_name': product.Title,
                                'item_category': product.CategoryTitle,
                                'item_category2': product.CategoryTitle2,
                                'item_category3': product.CategoryTitle3,
                                'discount': product.Sale ? product.Price - product.RefPrice : 0,
                            }]
                        }
                    }

                    // @ts-ignore
                    window.dataLayer.push(gme_add_to_wishlist);

                    //@ts-ignore
                    window.dataLayer.push(basketDataLayer);
                }

            }).catch(handleApiError);
        }).catch(handleApiError);
    }

    function removeBookmark(id: number) {
        Client.Bookmark.remove(auth.Key, member.Member.BookmarkCollections[0].ID, id).then((res) => {
            toast.info('Produkt von der Merkliste entfernt.')
            Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setAllBookmarks(res.data.Products);
                setBookmarkAmount(res.data.Count)

            }).catch(handleApiError);
        }).catch(handleApiError);
    }


    function getProductsToShow() {
        setViewProducts(viewProducts + maxProductCountToLoadForPaginationOnVendorPageInformation);
        setProductsToShow(productsToShow + maxProductCountToLoadForPaginationOnVendorPageInformation);
        setPage(page + 1);
    }

    function getProductsToHide() {
        setViewProducts(viewProducts - maxProductCountToLoadForPaginationOnVendorPageInformation);
        setProductsToShow(productsToShow - maxProductCountToLoadForPaginationOnVendorPageInformation);
        setPage(page - 1);
    }

    function getProductsToShowLast() {
        setViewProducts(maxProductCountToLoadForPaginationOnVendorPageInformation * (Math.ceil(products.length / maxProductCountToLoadForPaginationOnVendorPageInformation)) - maxProductCountToLoadForPaginationOnVendorPageInformation);
        setProductsToShow(maxProductCountToLoadForPaginationOnVendorPageInformation * (Math.ceil(products.length / maxProductCountToLoadForPaginationOnVendorPageInformation)));
        setPage(Math.ceil(products.length / maxProductCountToLoadForPaginationOnVendorPageInformation));
    }

    function getProductsToShowFirst() {
        setViewProducts(0);
        setProductsToShow(maxProductCountToLoadForPaginationOnVendorPageInformation);
        setPage(1);
    }

    return (
        <>
            <h2 className="text-center pb-5 pt-5" ref={productRef}>Produkte</h2>
            {loading ?
                <Loading type={"border"} message={"Produkte werden geladen"}/> :
                <>
                    {!products || !products.length ?
                        <h2 className={"text-center"}> Keine Produkte vorhanden </h2>
                        :
                        <>
                            {console.log(products)}
                            <Row className={"justify-content-center"}>
                                {products.slice(viewProducts, productsToShow).map((product, index) => {

                                    return (
                                        <>
                                            {product.Hidden !== 1 && product.Draft !== 1 || showAnyway ?
                                                (!product.Title.includes('Versandkosten') ?
                                                    <Col className={style.holder} lg={4}
                                                         xs={6}
                                                         key={index}>
                                                        <div
                                                            className={style.link}>
                                                            <div className={style.iconHolder}>
                                                                {product.Sale === 1 ? <span
                                                                    className={"badge position-absolute translate-middle " + style.saleBadge}>Sale</span> : null}
                                                                <Link
                                                                    to={`/${product.CategoryURLTitle}/${product.URLTitle}`}>
                                                                    {product.PreviewImage ?
                                                                        <img src={product.PreviewImage}
                                                                             className={style.icon}/> :
                                                                        <StaticImage
                                                                            src={'../../../images/login-overlay2x.png'}
                                                                            alt={'background'}
                                                                            placeholder="blurred"
                                                                            className={style.icon}/>
                                                                    }
                                                                </Link>
                                                                {auth.Key ?
                                                                    <>
                                                                        <OverlayTrigger
                                                                            key={"top-topsell"}
                                                                            placement={"top"}
                                                                            overlay={
                                                                                <Tooltip id={`tooltip-top-topsell`}>
                                                                                    {allBookmarks ?
                                                                                        <>
                                                                                            {(allBookmarks.find((bookmark) => {
                                                                                                return bookmark.ID === product.ID;
                                                                                            })) ?
                                                                                                <span> Von der Merkliste entfernen </span> :
                                                                                                <span> Zur Merkliste hinzufügen </span>} </> : null
                                                                                    }

                                                                                </Tooltip>
                                                                            }

                                                                        >
                                                                            <>
                                                                                {allBookmarks ?
                                                                                    <>
                                                                                        {(allBookmarks.find((bookmark) => {
                                                                                            return bookmark.ID === product.ID;
                                                                                        })) ?
                                                                                            <span className={style.crossHolder}
                                                                                                  onClick={() => removeBookmark(product.ID)}>
                                                                <FontAwesomeIcon icon={faTimes}
                                                                                 className={style.cross}/>
                                                            </span> :
                                                                                            <span className={style.heartHolder}
                                                                                                  onClick={() => {
                                                                                                      addBookmark(product.ID, product)
                                                                                                  }}>
                                                        <FontAwesomeIcon icon={faHeart} className={style.heart}/>
                                                        <FontAwesomeIcon icon={hoverHeart}
                                                                         className={style.hoverHeartClass}/>
                                                             </span>
                                                                                        }
                                                                                    </> :
                                                                                    <>
                                                                                        {member.MemberID ?

                                                                                            <span className={style.heartHolder}
                                                                                                  onClick={() => {
                                                                                                      addBookmark(product.ID, product)
                                                                                                  }}>
                                                        <FontAwesomeIcon icon={faHeart} className={style.heart}/>
                                                        <FontAwesomeIcon icon={hoverHeart}
                                                                         className={style.hoverHeartClass}/>
                                                             </span> : null
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        </OverlayTrigger>

                                                                    </> : null

                                                                }
                                                            </div>
                                                        </div>

                                                        <h5 className={style.category}>
                                                            {product.CategoryTitle}
                                                        </h5>
                                                        <h3 className={style.name}>
                                                            {product.Title}
                                                        </h3>
                                                        {product.Sale === 1 ?
                                                            <h3 className={style.price}>
                                                        <span
                                                            className={style.refPrice}>{product.RefPriceNice}</span> {product.NicePrice}
                                                            </h3>
                                                            : <h3 className={style.price}>
                                                                {product.NicePrice}
                                                            </h3>}

                                                    </Col> : null) : null
                                            }
                                        </>
                                    )
                                })}
                            </Row>

                            <div
                                className={"w-100 d-flex justify-content-center align-items-center " + style.buttonsContainer}>
                                {page === 1 ?
                                    <Button onClick={() => {
                                        getProductsToShowFirst();
                                        window.scrollTo(0, 0)
                                    }} className={style.showMore + ' ' + style.disabledButton} disabled>
                                        <span><span className={"d-lg-block d-none"}>Zur ersten Seite</span><span className={"d-lg-none d-block"}><FontAwesomeIcon icon={faChevronDoubleLeft}/></span></span>
                                    </Button>
                                    :
                                    <Button onClick={() => {
                                        getProductsToShowFirst();
                                        window.scrollTo(0, 0)
                                    }} className={style.showMore}>
                                        <span><span className={"d-lg-block d-none"}>Zur ersten Seite</span><span className={"d-lg-none d-block"}><FontAwesomeIcon icon={faChevronDoubleLeft}/></span> </span>
                                    </Button>
                                }

                                {page === 1 ?
                                    <Button  onClick={() => {
                                        getProductsToHide();
                                        window.scrollTo(0, 0)
                                    }} className={style.showMore + ' ' + style.disabledButton} disabled>
                                        <span> <span className={"d-lg-block d-none"}>Zurück</span><span className={"d-lg-none d-block"}><FontAwesomeIcon icon={faChevronLeft}/></span> </span>
                                    </Button>
                                    :
                                    <Button onClick={() => {
                                        getProductsToHide();
                                        window.scrollTo(0, 0)
                                    }} className={style.showMore}>
                                        <span> <span className={"d-lg-block d-none"}>Zurück</span><span className={"d-lg-none d-block"}><FontAwesomeIcon icon={faChevronLeft}/></span> </span>
                                    </Button>
                                }


                                {page} / {Math.ceil(products.length / maxProductCountToLoadForPaginationOnVendorPageInformation)}


                                {page === Math.ceil(products.length / maxProductCountToLoadForPaginationOnVendorPageInformation) ?
                                    <Button onClick={() => {
                                        getProductsToShow();
                                        window.scrollTo(0, 0)
                                    }}
                                            className={style.showMore + ' ' + style.disabledButton} disabled>
                                        <span> <span className={"d-lg-block d-none"}>Weiter</span><span className={"d-lg-none d-block"}><FontAwesomeIcon icon={faChevronRight}/></span> </span>
                                    </Button>
                                    :
                                    <Button onClick={() => {
                                        getProductsToShow();
                                        window.scrollTo(0, 0)
                                    }} className={style.showMore}>
                                        <span> <span className={"d-lg-block d-none"}>Weiter</span><span className={"d-lg-none d-block"}><FontAwesomeIcon icon={faChevronRight}/></span> </span>
                                    </Button>
                                }

                                {page === Math.ceil(products.length / maxProductCountToLoadForPaginationOnVendorPageInformation) ?
                                    <Button  onClick={() => {
                                        getProductsToShowLast();
                                        window.scrollTo(0, 0)
                                    }}   className={style.showMore + ' ' + style.disabledButton} disabled>
                                        <span> <span className={"d-lg-block d-none"}>Zur letzten Seite</span><span className={"d-lg-none d-block"}><FontAwesomeIcon icon={faChevronDoubleRight}/></span> </span>
                                    </Button>
                                    :
                                    <Button onClick={() => {
                                        getProductsToShowLast();
                                        window.scrollTo(0, 0)
                                    }} className={style.showMore}>
                                        <span> <span className={"d-lg-block d-none"}>Zur letzten Seite</span><span className={"d-lg-none d-block"}><FontAwesomeIcon icon={faChevronDoubleRight}/></span> </span>
                                    </Button>
                                }
                            </div>
                        </>
                    }
                </>
            }
        </>
    )
}

export default connector(OffersPreview);
