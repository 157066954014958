import * as React from "react";
import {FC} from "react";
import {Col, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './VendorImages.module.scss';
import 'react-fancybox/lib/fancybox.css'


type Props = {
    vendor: any
}

const VendorImages: FC<Props> = ({vendor}) => {
    return (
        <div className={style.imageContainer}>
            <h2 className={"text-center pt-5"}>Bilder</h2>
            {vendor.VendorImages ?
                <Row>
                    {vendor.VendorImages.map((image, index) =>
                        <Col lg={3} xs={6} key={index} className={style.productHolder}>

                            <img src={image} className={style.product}/>
                        </Col>
                    )}

                </Row> : <h2>Keine Bilder vorhanden</h2>
            }
        </div>
    )
}

export default VendorImages;
