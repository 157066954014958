import * as React from "react";
import {FC} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// @ts-ignore
import * as style from './MapMarker.module.scss';
import {faMapMarkerAlt} from "@fortawesome/pro-duotone-svg-icons";

const MapMarker: FC<any> = () => {
    return (
        <div className={style.pin}>
            <FontAwesomeIcon icon={faMapMarkerAlt}/>
        </div>
    )
}

export default MapMarker;
