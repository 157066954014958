import * as React from "react";
import {FC} from "react";
import OffersPreview from "../OffersPreview/OffersPreview";

type Props = {
    vendor: any
}

const VendorProducts: FC<Props> = ({vendor, loading}) => {
    return (
        <OffersPreview products={vendor.ActiveProducts} loading={loading}/>
    )
}

export default VendorProducts;
