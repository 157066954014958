import * as React from "react";
import {FC} from "react";
// @ts-ignore
import GoogleMapReact from 'google-map-react';
import MapMarker from "./MapMarker";
import {connect, ConnectedProps} from "react-redux";
import {setShowCookieOverlay} from "../../stores/cookies/cookieSlice";


const mapState = ({cookie}) => ({
    cookie
})

const mapDispatch = {
    setShowCookieOverlay
}


const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

const GoogleMap: FC<PropsFromRedux & any> = ({address, cookie, setShowCookieOverlay}) => {
    return (
        cookie.FunctionalCookies ?
            <>
                {typeof address !== "undefined" && address.Latitude && address.Longitude ?
                    <div style={{height: '50vh', width: '100%'}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{
                                key: 'AIzaSyANJcqINQiPqLLC2C_BWb0P5lg7FonHH6Q',
                                language: 'de',
                                region: 'at'
                            }}
                            defaultCenter={
                                {
                                    lat: address.Latitude,
                                    lng: address.Longitude
                                }
                            }
                            defaultZoom={14}
                            options={undefined}
                        >
                            <MapMarker lat={address.Latitude} lng={address.Longitude} text={"test"}/>
                        </GoogleMapReact>
                    </div>
                    : null
                }
            </> : <div style={{height: '25vh', width: '100%'}}
                       className={"d-flex flex-column justify-content-center align-items-center"}>

                <div>
                    Um diesen Inhalt zu sehen müssen funktionelle Cookies akzeptiert werden!
                </div>
                <a href={"javascript:void(0)"} className={"mt-2"} onClick={() => setShowCookieOverlay(true)}>
                    Cookie-Einstellungen bearbeiten
                </a>
            </div>


    )
}

export default connector(GoogleMap);
