import * as React from "react";
import {Container} from "react-bootstrap";
// @ts-ignore
import * as style from "./VendorROWs.module.scss";

const VendorROWs = ({vendor}) => {
    return (
        <Container className={"mt-5"}>
            <h2 className={"text-center"}>Widerrufsrecht</h2>
            <span dangerouslySetInnerHTML={{__html: vendor.ReturnPolicy}}>
            </span>
        </Container>
    )
}

export default VendorROWs;
